import './alarm-suppression-panel.scss';

import Component from 'vue-class-component';
import Vue from 'vue';
import {Inject} from 'vue-property-decorator';
import {AlarmStateTracker} from './alarm-state-tracker';
import {IAlarmState} from './alarm-state-dto-mapper';

@Component({
    template: require('./alarm-suppression-panel.html')
})
export class AlarmSuppressionPanel extends Vue {

    @Inject()
    private alarmStateTracker: AlarmStateTracker;

    private alarmState: IAlarmState | null = null;

    constructor() {
        super();

        this.alarmStateTracker.registerAlarmStateCallback(s => this.updateAlarmState(s));
    }

    private updateAlarmState(alarmState: IAlarmState) {
        this.alarmState = alarmState;
    }
}
