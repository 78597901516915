import {Moment} from 'moment';
import * as moment from 'moment';

export class AlarmStateDtoMapper {

    public mapDto(dto: IAlarmStateDto): IAlarmState {
        return {
            alarmActivatedAt: dto.alarmActivatedAt ? moment(dto.alarmActivatedAt) : null,
            alarmSuppressionActive: dto.alarmSuppressionActive,
            leraAlarmThresholdReached: dto.leraAlarmThresholdReached,
            praeAlarmThresholdReached: dto.praeAlarmThresholdReached,
            resetRequested: dto.resetRequested ? {
                requestedAt: moment(dto.resetRequested.requestedAt),
                requestedBy: dto.resetRequested.requestedBy
            } : null
        };
    }
}

export interface IAlarmStateDto {
    alarmActivatedAt: string;
    alarmSuppressionActive: boolean;
    leraAlarmThresholdReached: boolean;
    praeAlarmThresholdReached: boolean;
    resetRequested: {
        requestedAt: string;
        requestedBy: string;
    } | null;
}

export interface IAlarmState {
    alarmActivatedAt: Moment | null;
    alarmSuppressionActive: boolean;
    leraAlarmThresholdReached: boolean;
    praeAlarmThresholdReached: boolean;
    resetRequested: {
        requestedAt: Moment;
        requestedBy: string;
    } | null;
}
