import * as moment from 'moment';
import {Moment} from 'moment';
import {AlarmStateDtoMapper, IAlarmState, IAlarmStateDto} from './alarm-state-dto-mapper';

export class AlarmStateLogsDtoMapper {

    constructor(private alarmStateDtoMapper: AlarmStateDtoMapper) {
    }

    public mapDto(dto: IAlarmStateLogsDto): IAlarmStateLog[] {

        if (!dto || !dto.logs) {
            return [];
        }

        return dto.logs.map((l: any) => ({
            timestamp: moment(l.timestamp),
            alarmState: this.alarmStateDtoMapper.mapDto(l.alarmState)
        }));
    }
}

export interface IAlarmStateLogsDto {
    logs: Array<{
        timestamp: string;
        alarmState: IAlarmStateDto
    }>;
}

export interface IAlarmStateLog {
    timestamp: Moment;
    alarmState: IAlarmState;
}
