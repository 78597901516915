import {HubConnection, HubConnectionBuilder} from '@aspnet/signalr';

export class ConnectionTracker {

    private connected: boolean = false;

    private reconnecting: boolean = false;

    private connectionObservers: Array<((connected: boolean, reconnecting: boolean) => void)> = [];


    public static Create(stationLinkBaseUrl: string, apiToken: string) {

        let options = { accessTokenFactory: () => apiToken };

        let connection = new HubConnectionBuilder()
            .withUrl(`${stationLinkBaseUrl}/api/v1/val-bondasca/notifications`, options)
            .build();

        return new ConnectionTracker(connection);
    }

    constructor(private connection: HubConnection) {
        this.connection.start()
            .then(() => this.connectionChanged(true))
            .catch(() => this.connectionChanged(false));

        this.connection.onclose(() => this.connectionChanged(false));
    }

    public registerHandler(methodName: string, callback: ((...args: any[]) => void)) {
        this.connection.on(methodName, callback);
    }

    public registerConnectionObserver(callback: (connected: boolean, reconnecting: boolean) => void) {
        this.connectionObservers.push(callback);
        callback(this.connected, this.reconnecting);
    }

    private connectionChanged(connected: boolean) {

        this.connected = connected;

        if (connected) {
            this.reconnecting = false;
        } else {
            this.reconnecting = true;
            this.reconnect();
        }

        this.connectionObservers.forEach(o => o(this.connected, this.reconnecting));
    }

    private reconnect(retryCount = 0) {

        if (this.connected) {
            return;
        }

        let backOffTime = 1000 * Math.pow(2, retryCount > 5 ? 5 : retryCount);

        this.connection.start()
            .then(() => this.connectionChanged(true))
            .catch(() => setTimeout(() => this.reconnect(retryCount + 1), backOffTime));
    }
}
