import './app.scss';

import Vue from "vue";
import Component from "vue-class-component";
import {Inject, Provide} from "vue-property-decorator";
import {IAppConfig} from '../app-config';

const queryString = require('query-string');

import {AlarmStateTracker} from './alarm-state-tracker';
import {AlarmIndicator} from './alarm-indicator';
import {AlarmResetPanel} from './alarm-reset-panel';
import {ApiClient} from './api-client';
import {ConnectionTracker} from './connection-tracker';
import {ConnectionStatePanel} from './connection-state-panel';
import {AlarmStateDtoMapper} from './alarm-state-dto-mapper';
import {AlarmStateLogsPanel} from './alarm-state-logs-panel';
import {AlarmSuppressionPanel} from './alarm-suppression-panel';
import {AlarmStateLogsDtoMapper} from './alarm-state-logs-dto-mapper';

@Component({
    template: require('./app.html'),
    components: {
        AlarmIndicator,
        AlarmResetPanel,
        ConnectionStatePanel,
        AlarmStateLogsPanel,
        AlarmSuppressionPanel
    }
})
export default class App extends Vue {

    @Inject()
    private appConfig: IAppConfig;

    @Provide()
    private apiClient: ApiClient;

    @Provide()
    private connectionTracker: ConnectionTracker;

    @Provide()
    private alarmStateTracker: AlarmStateTracker;

    @Provide()
    private alarmStateLogsDtoMapper: AlarmStateLogsDtoMapper;

    private alarmResetPanelEnabled = false;

    constructor() {
        super();
        let query = queryString.parse(location.search);

        let alarmStateDtoMapper = new AlarmStateDtoMapper();

        this.alarmStateLogsDtoMapper = new AlarmStateLogsDtoMapper(alarmStateDtoMapper);

        this.connectionTracker = ConnectionTracker.Create(this.appConfig.stationLinkBaseUrl, query.apiToken);
        this.alarmStateTracker = new AlarmStateTracker(this.connectionTracker, alarmStateDtoMapper, this.alarmStateLogsDtoMapper);
        this.apiClient = new ApiClient(this.appConfig.stationLinkBaseUrl, query.apiToken);

        this.apiClient.getPermissions().then(p => this.enablePermissionBasedFeatures(p));
    }

    private enablePermissionBasedFeatures(permissions: string[]) {
        this.alarmResetPanelEnabled = permissions.indexOf('ValBondascaResetAlarm') !== -1;
    }
}

