import Component from 'vue-class-component';
import Vue from 'vue';
import {Inject} from 'vue-property-decorator';
import {AlarmStateTracker} from './alarm-state-tracker';
import {AlarmStateInfo} from './alarm-state-info';
import {IAlarmState} from './alarm-state-dto-mapper';

@Component({
    template: require('./alarm-indicator.html'),
    components: { AlarmStateInfo }
})
export class AlarmIndicator extends Vue {

    @Inject()
    private alarmStateTracker: AlarmStateTracker;

    private alarmState: IAlarmState | null = null;

    constructor() {
        super();

        this.alarmStateTracker.registerAlarmStateCallback(s => {
            this.updateAlarmState(s);
        });
    }

    private updateAlarmState(alarmState: IAlarmState) {
        this.alarmState = alarmState;
    }

}
