import './alarm-reset-panel.scss';

import Vue from 'vue';
import Component from 'vue-class-component';
import {Inject} from 'vue-property-decorator';
import {AlarmStateTracker} from './alarm-state-tracker';
import {ApiClient} from './api-client';
import {IAlarmState} from './alarm-state-dto-mapper';

@Component({
    template: require('./alarm-reset-panel.html')
})
export class AlarmResetPanel extends Vue {

    @Inject()
    private alarmStateTracker: AlarmStateTracker;

    @Inject()
    private apiClient: ApiClient;

    private alarmState: IAlarmState | null = null;

    private postingAlarmResetRequest = false;

    constructor() {
        super();

        this.alarmStateTracker.registerAlarmStateCallback(s => {
            this.updateAlarmState(s);
        });
    }

    private get resetPossible() {
        return this.alarmState
            && this.alarmState.alarmActivatedAt
            && !this.alarmState.leraAlarmThresholdReached
            && !this.alarmState.praeAlarmThresholdReached
            && !this.alarmState.resetRequested;
    }

    private updateAlarmState(alarmState: IAlarmState) {
        this.alarmState = alarmState;
    }

    private requestReset() {
        this.postingAlarmResetRequest = true;
        return this.apiClient.requestAlarmReset().then(() => this.requestResetCompleted());
    }

    private requestResetCompleted() {
        this.postingAlarmResetRequest = false;
    }

}
