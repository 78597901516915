import './alarm-state-logs-panel.scss';

import Component from 'vue-class-component';
import {AlarmStateInfo} from './alarm-state-info';
import Vue from 'vue';
import {Inject} from 'vue-property-decorator';
import {Moment} from 'moment';
import * as moment from 'moment';
import {AlarmStateTracker} from './alarm-state-tracker';
import {IAlarmState} from './alarm-state-dto-mapper';
import {ConnectionTracker} from './connection-tracker';
import {AlarmStateLogsDtoMapper, IAlarmStateLog} from './alarm-state-logs-dto-mapper';

@Component({
    template: require('./alarm-state-logs-panel.html'),
    components: { AlarmStateInfo }
})
export class AlarmStateLogsPanel extends Vue {

    @Inject()
    private alarmStateTracker: AlarmStateTracker;

    @Inject()
    private connectionTracker: ConnectionTracker;

    @Inject()
    private alarmStateLogsDtoMapper: AlarmStateLogsDtoMapper;

    private logs: { [timestamp: string]: IAlarmStateLog; } = {};

    private now: Moment = moment();

    constructor() {
        super();

        this.alarmStateTracker.registerAlarmStateCallback((state, isInitial) => this.addNewAlarmStateLog(state, isInitial));

        this.connectionTracker.registerHandler(
            "AlarmStateLogs",
                dto => this.updateAlarmStateLogs(this.alarmStateLogsDtoMapper.mapDto(dto)));

        setInterval(() => this.updateNow(), 15 * 1000);
    }

    private updateNow() {
        this.now = moment();
    }

    private addNewAlarmStateLog(alarmState: IAlarmState, isInitial: boolean) {

        // The initial state is already in the logs, so we skip it
        if (isInitial) {
            return;
        }

        let timestamp = moment();

        // Directly updating this.logs does not trigger a Vue change, so we recreate the whole dictionary
        let logs = this.logEntries;
        logs.unshift({ timestamp, alarmState });
        this.logs = this.toDictionary(logs);

        this.updateNow(); // Ensure a correct calculation of the 'time from' text
    }

    private updateAlarmStateLogs(logs: IAlarmStateLog[]) {
        this.logs = this.toDictionary(logs);
    }

    private toDictionary(logs: IAlarmStateLog[]) {

        let dict: { [timestamp: string]: IAlarmStateLog; } = {};

        logs.forEach(l => { dict[l.timestamp.toISOString()] = l; });
        return dict;
    }

    private get logEntries() {
        return Object.keys(this.logs).map(key => this.logs[key]);
    }

    private isToday(timestamp: Moment) {
        return timestamp.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD');
    }
}
