
import {ConnectionTracker} from './connection-tracker';
import {AlarmStateDtoMapper, IAlarmState, IAlarmStateDto} from './alarm-state-dto-mapper';
import {AlarmStateLogsDtoMapper, IAlarmStateLogsDto} from './alarm-state-logs-dto-mapper';

export class AlarmStateTracker {

    private alarmStateCallbacks: Array<((update: IAlarmState, initialState: boolean) => void)> = [];

    constructor(
        private connectionTracker: ConnectionTracker,
        private alarmStateDtoMapper: AlarmStateDtoMapper,
        private alarmStateLogsDtoMapper: AlarmStateLogsDtoMapper) {

        this.connectionTracker.registerHandler(
            'AlarmStateUpdate',
            dto => this.onAlarmStateUpdate(dto));

        // The initial state is read from the logs, so we register that handler as well
        this.connectionTracker.registerHandler(
            'AlarmStateLogs',
            dto => this.onAlarmStateLogs(dto));
    }

    private onAlarmStateUpdate(dto: IAlarmStateDto) {

        let alarmState = this.alarmStateDtoMapper.mapDto(dto);

        this.alarmStateCallbacks.forEach(c => c(alarmState, false));
    }

    private onAlarmStateLogs(dto: IAlarmStateLogsDto) {

        let logs = this.alarmStateLogsDtoMapper.mapDto(dto);

        let current = logs[0];

        this.alarmStateCallbacks.forEach(c => c(current.alarmState, true));
    }

    public registerAlarmStateCallback(callback: ((update: IAlarmState, initialState: boolean) => void)) {
        this.alarmStateCallbacks.push(callback);
    }
}

